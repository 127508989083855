import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  getItem(name?: string): string {
    return (name && localStorage.getItem(name)) ?? '';
  }

  clear(): void {
    localStorage.clear();
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  popItem(name: string): string {
    const item = localStorage.getItem(name);
    if (item) {
      localStorage.removeItem(name);
      return item;
    }

    return '';
  }
}
